@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    body {
        padding-top: 50px;
    }

	#detailsPane, #addDevicePane, #createGroupPane {
		width: 600px !important;
	}
}

@media (max-width: 1200px) {
	.usersDiv .addUserButton {
		margin-right: 15px !important;
	}

	.navbar ul button:nth-child(1) {
		margin-top: 0;
		width: 180px;
	}

	.navbar ul button:nth-child(2), .navbar ul button:nth-child(3), .userIconNavLink {
		margin-top: 20px;
		width: 180px;
	}

	.logoutLink, .userIconNavLink {
		display: inline;
	}

	.navItemLogout {
		margin-top: 10px;
		margin-bottom: 5px;
	}

	.navItemUser {
		margin-top: 20px;
	}

	.navItems .routes {
		position: static !important;
		padding-left: 0 !important;
	}

	.navbar-collapse {
		margin-top: 10px;
	}

	#pageBody {
		padding-left: 0;
		padding-right: 0;
	}

	.usersDiv .addUserButton {
		margin-right: 10px !important;
	}
}

@media (max-width: 576px) {
	#detailsPane, #addDevicePane, #createGroupPane {
		width: 500px !important;
	}
}

.myGrid {
    margin-left: 0;
    background-color: #7398AA;
    color: white;
}

html, body {
    background-color: black;
    color: #f0f2f3;
    margin: 0;
    padding: 0;
    height: 100%;
}

#root {
    min-height: 100%;
    position: relative;
}

#pageContainer {
    min-height: 100%;
}

#pageBody {
    padding-bottom: 60px; /* Height of the footer */
}

.footer {
    background-color: darkslategray;
    height: 60px;
    position: absolute;
    bottom: 0;
}

.footerRow {
    height: 60px;
}

.footer span {
    font-size: 10px;
    margin-right: 20px;
}

.footer a {
    color: white;
}

.emptyRow {
    padding: 16px 10px;
}

.table tr {
    margin: auto;
}

.table .connectedDeviceRow:hover {
    background-color: black;
    cursor: pointer;
    margin: auto;
}

.table .selectedRow {
    background-color: black;
}

.table {
    border: none;
    color: white;
}

.table th {
    border: none;
    padding-left: 10px;
    padding-right: 10px;
}

.table td {
    border: none;
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 10px;
    padding-right: 10px;
}

.navbar-light .navbar-brand {
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.nav-link {
    color: white !important;
}

.navItems .active, .routes:hover {
    background-color: #171717;
    color: white !important;
}

.navItems {
    position: relative;
}

.navItems .routes {
    position: absolute;
    height: 43px;
    top: -15px;
    padding-top: 3px;
    color: gray;
    text-decoration: none;
}

.navItems .routes:nth-child(1) {
    left: 0;
}

.navItems .routes:nth-child(2) {
    left: 94px;
}

.routes {
    color: white;
    padding: 0 20px 0 20px;
}

.table th {
    border-color: black;
    color: #999999;
    border-bottom: none !important;
}

.gridHeader {
    background-color: #252C3F;
    margin-bottom: 20px;
    margin-top: 5px;
    height: 50px;
}

.gridLeftPane {
    margin-top: 8px !important;
    padding-left: 0px !important;
}

.rowBody {
    margin-top: 15px;
}

.bodyH3 {
    margin-top: 30px;
}

.navbar {
    margin-bottom: 0 !important;
    position: sticky;
    top: 0;
    background-color: black;
    z-index: 1000;
}

.logo {
    padding-top: 0 !important;
    padding-left: 0 !important;
}
.disconnected {
    background-color: #171717 !important;
}

#detailsPane .detailsPaneWait {
    cursor: wait !important;
}

#detailsPane, #addDevicePane {
    width: 580px;
    /*height: 100vh;*/
	height: 97vh;
    background-color: #171717;
    color: #999999;
    position: fixed;
    top: 56px;
    right: 0;
    transition: transform .3s;
    z-index: 1000;
    border-left: 2px solid black;
    border-top: 2px solid black;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    overflow-y: scroll;
}
#detailsPane.hide {
    transform: translate3d(680px, 0, 0);
}
#detailsPane.show {
    transform: translate3d(0vw, 0, 0);
    box-shadow: 0px 0px 1px 5000px rgba(0,0,0,0.8);
}

#addDevicePane.hide {
    transform: translate3d(680px, 0, 0);
}
#addDevicePane.show {
    transform: translate3d(0vw, 0, 0);
    box-shadow: 0px 0px 1px 5000px rgba(0,0,0,0.8);
}

#detailsPane.showAndWait {
    transform: translate3d(0vw, 0, 0);
    box-shadow: 0px 0px 1px 5000px rgba(0,0,0,0.8);
    cursor: wait !important;
}
#detailsPane .container {
    padding-left: 4px;
    padding-right: 2px;
}
#detailsPane .saveButton {
    float: right;
    margin-right: 7px;
}
#detailsPane .applyingButton {
    cursor: wait;
}
#detailsPane .cancelButton {
    float: right;
}

#detailsPane h3 {
    margin-top: 0;
    margin-bottom: 0px;
    color: white;
}
#detailsPane Form {
    color: white !important;
}

#addDevicePane h3 {
    color: white;
}

#createGroupPane {
    width: 680px;
    height: 100vh;
    background-color: #171717;
    color: #999999;
    position: fixed;
    top: 56px;
    right: 0;
    transition: transform .3s;
    z-index: 1000;
    border-left: 2px solid black;
    border-top: 2px solid black;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    overflow-y: scroll;
}

#createGroupPane.hide {
    transform: translate3d(680px, 0, 0);
}

#createGroupPane.show {
    transform: translate3d(0vw, 0, 0);
    box-shadow: 0px 0px 1px 5000px rgba(0,0,0,0.8);
}

#createGroupPane .row {
    margin-left: 0;
}

#createGroupPane h3 {
    margin-bottom: 20px;
    color: white;
}

.addDeviceHeader {
    margin-bottom: 20px !important;
    margin-left: -15px;
}

.rowEnterGroupName {
    margin-top: 10px;
}

.rowEnterGroupName input {
    width: 300px;
    display: inline-block;
}

.rowEnterGroupName button {
    margin-left: 10px;
    margin-bottom: 5px;
}

.myNavbar {
    background-color: #4D4D4D;
    border-radius: 0 !important;
}

.detailsAccordion {
    margin-bottom: 10px;
    margin-top: 10px;
}

.card {
    background-color: #E7E7E7;
}

.airServerLog {
    word-break: break-all;
    white-space: normal;
}

.form-label {
    color: #999999;
}

.form-control {
    color: #999999;
    background-color: #171717;
    border-radius: 0px;
}

.short-form-control {
    width: 50% !important;
    /*display: inline !important;*/
}

.multipleEditPasswordInput {
    width: 73.5% !important;
}

.short-form-control-disabled {
    background-color: #171717 !important;
}

.form-control-disabled {
    background-color: #171717 !important;
}

.inputWithPlusX {
    display: inline;
}

.roomForPlusX {
	width: 90% !important;
}

.securityTypesMultiple {
	width: 90% !important;
	display: inline-block;
	margin-right: 5px;
}

.labelWithPlusX {
    display: block;
}

.plusX {
    margin-left: 5px;
    margin-top: 7px;
}

.plusX:hover {
    cursor: pointer;
}

.card-header {
    background-color: #171717;
    color: #999999;
}

.card-body {
    background-color: #171717;
    color: #999999;
}

.submitButtons {
    padding-bottom: 100px;
}

.plus-x-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
    display: inline;
}

.react-switch-checkbox, .react-switch-checkbox-disabled {
    height: 0;
    width: 0;
    visibility: hidden;
}

.react-switch-label, .react-switch-label-disabled {
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 38px;
    height: 19px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color .1s;
    /*top: 13px;*/
}

.react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 15px;
    height: 15px;
    border-radius: 45px;
    transition: 0.1s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-label-disabled .react-switch-button-disabled {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 15px;
    height: 15px;
    border-radius: 45px;
    transition: 0.1s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.checkboxLabel {
    position: relative;
    top: -13px;
    margin-left: 7px;
    margin-right: 0;
}

.invisiblePosition {
    top: -5px !important;
    margin-left: 0;
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
    width: 34px;
}

.groupContainer {
    padding-bottom: 4px;
    padding-top: 4px;
    margin-bottom: 20px;
    background-color: #171717;
    border-radius: 7px;
}

.tableDiv {
    position: relative;
    margin-bottom: 10px;
}

.groupNameRow {
    border-bottom: 1px solid white;
    padding-bottom: 6px;
}

.hoverButton {
    display: none;
    margin-left: 20px;
    padding-bottom: 5px;
}

.trashButton {
    margin-left: 7px !important;
}

.hoverButton:hover {
    cursor: pointer;
    color: dodgerblue;
}

.removeUserButton {
    margin-left: 17%;
    cursor: pointer;
}

.removeUserButton:hover {
    color: white;
}

.editUserButton {
    margin-left: 10%;
    cursor: pointer;
}

.editUserButton:hover {
    color: white;
}

.groupNameRow:hover .hoverButton {
    display: inline;
}

.orgNameFormGroup:hover .hoverButton {
	display: inline;
}

.orgNameFormGroup {
	margin-bottom: 20px;
}

.notHoverButton {
    /*Temp class until we decide if we want to go back to hoverButton class.*/
    margin-left: 20px;
    padding-bottom: 5px;
}

.notHoverButton:hover {
    cursor: pointer;
    color: dodgerblue;
}

.groupNameHeader {
    display: inline;
    margin-left: 8px;
}

.separatorWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.spaceDiv {
    margin-top: 20px;
}

.addDeviceButton {
    border-color: #6ce9b5;
    background-color: #00001A;
    border-width: 2px;
	margin-right: 10px;
}

.createGroupButton {
    border-color: #46C6FD;
    background-color: #00001A;
    border-width: 2px;
    margin-right: 10px;
}

.editButton {
    border-color: #46C6FD;
    background-color: #00001A;
    border-width: 2px;
    margin-right: 10px;
}

.statusOffline {
    color: #F53477;
}

.offlineLabel {
    color: red;
    margin-top: 5px;
    font-size: large;
}

.statusOnline {
    color: #6ce9b5;
}

.detailsButton {
    color: white;
}

.detailsButton:hover {
    color: dodgerblue;
    cursor: pointer;
}

.airplayIcon {
    margin-right: 10px;
}

.googleCastIcon {
    font-size: 20px;
    margin-right: 10px;
}

.miracastIcon {
    font-size: 17px;
    margin-right: 10px;
}

.expandButton {
    float: right;
    color: white;
    margin-right: 10px;
}

.expandButton:hover {
    cursor: pointer;
    color: dodgerblue;
}

.form_airplay {
    padding-left: 10px;
}

.form_miracast {
    padding-right: 40px;
}

.form_googleCast {
    padding-left: 10px;
}

.plusXShow {
    display: inline;
    align-items: center; /* vertical alignment */
    padding-bottom: 10px;
}

.plusXHide {
    display: none !important;
}

.plusX_guestConnectivity {
    padding-bottom: 30px;
}

.invisible {
    display: none;
}

.switchCol {
    margin-top: 10px;
    padding-left: 0 !important;
}

.moderatorSwitch {
	width: 135px !important;
}

.colScreenMirror {
    padding-top: 7px;
}

.detailFormButtons {
    padding-left: 0;
    padding-right: 0 !important;
}

.plusX_groupCheck {
    padding-left: 0;
    padding-right: 0;
}

.toolsButton {
    width: 100%;
}

.applyAndRebootButton {
    width: 100%;/*92%;*/
}

.powerOffDeviceButton {
    border-color: #F9B653;
}

.powerOffDeviceButton:hover {
    border-color: #F9B653;
    background-color: #F9B653;
}

.rebootDeviceButton {
    border-color: #4ACCFB;
}

.rebootDeviceButton:hover {
    border-color: #4ACCFB;
    background-color: #4ACCFB;
}

.resetSettingsButton {
    border-color: #F74C80;
	margin-top: 20px;
}

.resetSettingsButton:hover {
    border-color: #F74C80;
    background-color: #F74C80;
}

.removeDeviceButton {
    border-color: darkred;
    margin-right: 0;
    width: 100%;
}

.removeDeviceButton:hover {
    border-color: darkred;
    background-color: darkred;
}

.toolsContainer .row {
    margin-bottom: 20px;
}

.toolsContainer h6 {
    color: white;
    border-bottom: 1px solid white;
    padding-bottom: 15px;
}

.tabInfoSection {
    border-bottom: 1px solid white;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.rowCreateGroupButton {
    margin-top: 10px;
}

.elementMargin {
    margin-right: 5px;
}

.modal-content {
    background-color: white;
    color: black;
    border-radius: 10px;
}

nobr {
    white-space: nowrap;
    hyphens: none;
}

#copyright {
    font-size: 10px;
}

.navbarTitle b {
    color: white;
}

.groupColumn {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.groupColumnAdopting {
    margin-left: -20px;
}

.plusXPaddingLeft5 {
    padding-left: 5px;
}

.pIndent {
    margin-left: 15px;
}

.right-aligned {
    text-align: right;
}

.updatesButton {
    color: white;
	margin-top: 40px;
}

.plusX_automaticUpdateEnabled {
    margin-bottom: 12px;
}

.wifiAdapterCol {
    padding-left: 0 !important;
}

.wifiAdapterValue {
    color: orangered;
}

.wifiConnectionStatusTextConnected {
	color: limegreen;
}

.wifiConnectionStatusTextDisconnected {
	color: green;
}

.ipAddressRow {
    margin-bottom: 15px;
}

.interfaceRow {
    margin-bottom: 10px;
}

.interfaceNameLabel {
    color: limegreen;
}

.indentRow {
    margin-left: 1px;
}

.securityRow {
    margin-top: 20px;
}

.securityButton {
    width: 206px;
    margin-top: 10px;
}

.securityLabel {
    margin-left: 20px;
    color: limegreen;
}

.colorWhite {
    color: white;
}

.width75 {
    width: 75% !important;
}

.wifiInfoRows {
    border-top: 1px solid white;
}

.colCurrentChannel {
    padding-right: 28px !important;
}

.colCurrentChannel label {
    margin-right: 4px;
}

.lblGroup {
    background-color: #171717 !important;
    border: none;
    border-width: 0;
    box-shadow: none !important;
    border-color: #171717 !important;
    font-size: 24px;
    color: white !important;
    padding: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 8px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.tabLabel {
    font-size: xx-large;
    margin-bottom: 20px;
    color: white;
}

.securityTabLabel {
    font-size: xx-large;
    margin-bottom: 0;
    color: white;
}

.detailsRow {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.form-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.col {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.form-row > [class*="col-"] {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.toolsButtonFirstRow {
    margin-top: 150px;
}

.toolsButtonRow {
    margin-top: 20px;
}

.cloudTokenTextBox {
    width: 75%;
}

.formIsInvalid {
    border-color: darkred;
    border-width: 2px;
}

.errorInput {
    color: darkred;
    width: 160px;
}

.errorInputLong {
    color: darkred;
}

.enableDeviceManagementRow {
    margin-top: 30px;
}

.currentChannelRow {
    margin-top: 10px;
    width: 100% !important;
}

.accessTypeRow {
    margin-top: 0;
}

.accessTypeRowMultipleEdit {
    margin-top: 20px;
}

.ssidRow {
    margin-bottom: 10px;
}

.upper5Row {
    margin-top: 20px;
}

.clientIsolationRow {
    margin-top: 0px;
}

.applyAndRebootRow {
    margin-bottom: 40px;
}

.width100Group {
    margin-bottom: 0 !important;
    width: 100% !important;
}

.wifiGroup {
    margin-bottom: 0;
    width: 100% !important;
}

.automaticUpdateEnabledCol {
    margin-top: 7px;
}

.displayPowerManagementCol {
    padding-top: 5px;
}

.notification-container {
    position: fixed;
    bottom: 0;/*60px;*/
}

.dnsRow {
    margin-bottom: 20px;
}

.staticIpRow {
    margin-bottom: 5px;
}

.dns2Row {
    width: 148px;
}

.dns2AddButton {
    margin-bottom: 10px;
}

.dns2RemoveButton {
    margin-bottom: 5px;
}

.dns2MainRow {
    position: relative;
    top: -10px;
}

#userSettingsId {
    width: 30px;
    height: 30px;
}

.disconnectedLabel {
    margin-top: 7px;
    margin-left: 40px;
    color: darkred;
}

.userIconNavLink {
    padding-top: 5px;
    padding-bottom: 0;
    position: relative;
    top: -2px;
}

.addDeviceRow {
    margin-top: 20px;
}

.airplayDiv {
    position: relative;
}

.googleCastDiv {
    position: relative;
    top: 1px;
}

.miracastDiv {
    position: relative;
    top: 1px;
}

.tlsLabelCol {
    padding-right: 0 !important;
    margin-top: 30px;
}

.confirmExtraQ {
    margin-top: 20px;
}

.questionColor {
    color: black !important;
}

.noLeftAndRightPadding {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.detailsPaneHeaderCol {
    padding-left: 12px;
    padding-right: 0;
}

.GuestConnRightCol {
    padding-left: 40px;
    overflow-x: visible;
}

.leftSm {
    position: relative;
    left: 2px;
}

.experimental {
    color: #ddc413;
    margin-bottom: 20px;
    margin-left: 10px;
    position: relative;
    left: -15px;
    top: -4px;
}

.aboutForm {
    width: 100%;
}

.infoRowLink {
    text-decoration: underline;
}

.statusUpdating {
    color: yellow;
}

.screenMirroringRadioButtonWhite .form-check-label {
    color: white;
}

.screenMirroringRadioButtonYellow .form-check-label {
    color: yellow;
}

.screenMirroringRadioButtonGreen .form-check-label {
    color: green;
}

.castFromAppsRow {
    margin-top: 20px;
}

.securityTypeRow {
	margin-bottom: 30px;
}

.wifiAdapterText {
	margin-bottom: 10px;
}

.securityGroup {
	margin-top: 10px;
}

.wifiConnectionStatusLabel {
	margin-right: 40px;
}

.networkNameGroup {
	margin-bottom: 20px;
}

.groupShowGuestAccess {
    margin-bottom: 5px !important;
}

.groupBackgroundUrl {
    margin-bottom: 20px !important;
}

.termsDiv {
    color: red !important;
    background-color: green !important;
}

.usersDiv h2, .devicesDiv h2 {
    margin-bottom: 30px;
}

.usersDiv td {
    word-break: break-all;
}

.usersDiv .addUserButton {
    float: right;
	margin-right: 34px;
}

.addUserButton {
    background-color: #00001a;
    border-color: #46c6fd;
    border-width: 2px;
}

.userPageHeaderRow {
    border-bottom: 1px solid white;
    padding-bottom: 6px;
}

.usersDiv table {
    margin-bottom: 0;
    /*table-layout: fixed;*/
    width: 100%;
}

.usersDiv .table tbody tr:hover {
    background-color: black;
    cursor: pointer;
    color: white;
}

.addUserTd {
    padding-left: 0 !important;
}

.usersDiv table .titleTd {
    position: relative;
}

.usersDiv table .titleTd svg {
    position: absolute;
    top: 19px;
    display: inline-block;
    color: #b7ccd8;
}

.usersDiv table .titleTd h3 {
    position: absolute;
    left: 45px;
}

.breakWord {
    word-break: break-word !important;
}

/* rolesTable th widths: */
.rolesTable thead tr th:nth-child(1) {
    width: 22%;
}

.rolesTable thead tr th:nth-child(2) {
    width: 46%;
}

.rolesTable thead tr th:nth-child(3) {
    width: 17%;
}

.rolesTable thead tr th:nth-child(4) {
    width: 5%;
}

.rolesTable thead tr th:nth-child(5) {
    width: 10%;
}

/* usersTable th widths: */
.usersTable thead tr th:nth-child(1) {
    width: 22%;
}

.usersTable thead tr th:nth-child(2) {
    width: 26%;
}

.usersTable thead tr th:nth-child(3) {
    width: 20%;
}

.usersTable thead tr th:nth-child(4) {
    width: 17%;
}

.usersTable thead tr th:nth-child(5) {
    width: 5%;
}

.usersTable thead tr th:nth-child(6) {
    width: 10%;
}

/* aadTable th widths: */
.aadTable thead tr th:nth-child(1) {
    width: 48%;
}

.aadTable thead tr th:nth-child(2) {
    width: 37%;
}

.aadTable thead tr th:nth-child(3) {
    width: 5%;
}

.aadTable thead tr th:nth-child(4) {
    width: 10%;
}

/* Done with widths */

.usersDiv .managesAllGroups {
    font-weight: bold;
}

/* jumbotron part: */

.jumbotron {
    background-color: #171717;
    padding: 0 20px 10px 20px;
    padding-right: 0;
    padding-left: 0;
}

.jumbotron td {
    padding-right: 0;
}

.jumbotron h3 {
    display: inline-block;
    margin-bottom: 0;
}

.jumbotron .list-group-item {
    background-color: black;
    margin-bottom: 10px;
}

.jumbotron .list-group-item label {
    color: white;
    margin-right: 4px;
}

.jumbotron .list-group-item button {
    margin-right: 20px;
}

.addUserModal .modal-content {
    background-color: #171717 !important;
    color: white;
    box-shadow: 0px 0px 1px 5000px rgba(0,0,0,0.5);
	border-radius: 15px;
}
.addUserModal .modal-content .modal-body {
	padding-bottom: 0;
}

.addUserModal .modal-content .modal-header {
	border-bottom: 1px solid white;
}

.addUserModal .modal-content .modal-footer {
	border-top: none;
}

.addUserModal .modal-content .modal-header .close span {
	color: white;
}

.errorMessage {
    padding: 10px 10px 10px 0;
}

.errorMessage h3 {
    color: red;
}

.disabled-element, .disabled-element label {
	color: rgba(128, 128, 128, 0.548) !important;
}

.formGroupCanEditUsers {
	margin-top: 30px;
	margin-bottom: 0;
}

.comingSoon {
	color: #ddc413;
	font-size: 21px;
	margin-left: 30px;
	/* float: right; */
}

.firstLoginDiv {
	margin-top: 20px;
}

.whitesmokeText {
    color: whitesmoke;
}

.firstLoginPwGroup {
	width: 30%;
}

.errorText {
	color: rgb(255, 30, 0);
	font-size: large;
	margin-top: 20px;
}

.orgNameTitle {
	margin-bottom: 30px;
	display: inline;
}

/* .moderatorForm {
	width: 50%;
} */

.networkBasicInfo {
	border-bottom: 1px solid white;
	margin-bottom: 10px;
	padding-bottom: 10px;
}

.aadTable .addUserButton {
	visibility: hidden;
}

.react-ip-input {
	display: block !important;
}

.very-small {
    font-size: 0.775rem;
}

